import {createRouter, createWebHistory} from 'vue-router'
import ICU from '@/components/ICU.vue'


const routes = [
    {
        path: '/',
        redirect: '/Pacific_Region',
    },
    {
        path: '/:location',
        name: 'ICU',
        component: ICU,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;



