<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    // import ICU from './components/ICU.vue'
    // <router-link :to="{ name: 'ICU', params: { location: 'Pacific_Region' } }">Pacific Region</router-link>
    // <router-link :to="{ name: 'ICU', params: { location: 'Palau' } }">Palau</router-link>

    export default {
        name: 'App',
    }
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
