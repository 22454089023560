<template>
<div class="container">
    <div 
        v-for="tab in tabGroups"
        :key="tab.value"
        class="tab"
        :class="{ active: tab.value === activeTab }"
        @click="switchTab(tab.value)"
    >
        {{ tab.label }}
    </div>

    <div v-if="activeTab === 'recent_conditions'">
        <div class="container mt-4">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="location">Location</label>
                        <select class="form-control" id="location" v-model="selectedLocation" @change="updateProduct">
                            <option v-for="item in locations" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="product">Product</label>
                        <select class="form-control" id="product" v-model="selectedProduct" @change="updateImageUrl">
                            <option v-for="item in product" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="numDays">Number of Days</label>
                        <select class="form-control" id="numDays" v-model="selectedNumDays" @change="updateImageUrl">
                            <option v-for="item in numDays" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="activeTab === 'forecast_and_outlook'">
        <div class="container mt-4">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="location">Location</label>
                        <select class="form-control" id="location" v-model="selectedLocation" @change="updateDistribution">
                            <option v-for="item in locations" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="period">Period</label>
                        <select class="form-control" id="period" v-model="selectedPeriod" @change="updateLeadTime">
                            <option v-for="item in period" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="distribution">Distribution</label>
                        <select class="form-control" id="distribution" v-model="selectedDistribution"
                            @change="updateImageUrl" :disabled="distribution.length === 1">
                            <option v-for="item in distribution" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="leadTime">Lead Time in Months</label>
                        <select class="form-control" id="leadTime" v-model="selectedLeadTime" @change="updateImageUrl">
                            <option v-for="item in leadTime" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="activeTab === 'water_stress_outlook'">
        <div class="container mt-4" style="width: 70%">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="period">Period</label>
                        <select class="form-control" id="period" v-model="selectedPeriod" @change="updateNumDaysObservation">
                            <option v-for="item in period" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="numDayObs">Number of days observation to consider</label>
                        <select class="form-control" id="numDayObs" v-model="selectedNumDayObs" @change="updateImageUrl" disabled>
                            <option v-for="item in numDayObs" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <img :src="imageUrl" alt="image" />
</div>

<div>
    <p>
    {{ updateString }}:  {{ updateDate }}
    </p>
    <p>
    Credit to: <a href="https://niwa.co.nz/climate/island-climate-update" target="_blank">NIWA - Island Climate Update (ICU)</a>
    </p>
</div>


</template>

<script>
    import axios from 'axios';

    export default {
        mounted() {
            this.location = this.$route.params.location;
            this.parseImageUrl();
            this.matchLocation();
            this.updateProduct();
            this.selectedProduct = this.product[0].value;
            this.selectedNumDays = this.numDays[0];
            this.selectedPeriod = this.period[0].value;
            this.selectedDistribution = this.distribution[0];
            this.selectedLeadTime = this.leadTime[0];
            this.selectedNumDayObs = this.numDayObs[0];
            // this.parseUpdateDate("mswep", "updateDate");
            // this.parseUpdateDate("gpm", "GPMUpdateDate");
            this.parseUpdateDate("mswep", "MSWEPUpdateDate");
            this.parseUpdateDate("c3s", "C3SUpdateDate");
            this.parseUpdateDate("water_stress", "WaterStressUpdateDate");
        },
        data() {
            return {
                imageUrls: null,
                updateDate: null,
                // GPMUpdateDate: null,
                updateString: "Latest MSWEP Data Used",
                MSWEPUpdateDate: null,
                C3SUpdateDate: null,
                WaterStressUpdateDate: null,
                imageUrl: "",
                location: "Pacific_Region",
                activeTab: "recent_conditions",
                tabGroups: [
                    { value: "recent_conditions", label: "Recent Conditions" },
                    { value: "forecast_and_outlook", label: "Forecast and Outlook" },
                    { value: "water_stress_outlook", label: "Water Stress Outlook" }
                ],
                locations: [
                    {value: "Pacific_Region", label: "Pacific Region"},
                    {value: "American_Samoa", label: "American Samoa"},
                    {value: "Cook_Islands", label: "Cook Islands"},
                    {value: "Federated_States_of_Micronesia", label: "Federated States of Micronesia"},
                    {value: "Fiji", label: "Fiji"},
                    {value: "French_Polynesia", label: "French Polynesia"},
                    {value: "Guam", label: "Guam"},
                    {value: "Kiribati_Gilbert_Islands", label: "Kiribati Gilbert Islands"},
                    {value: "Kiribati_Line_Islands", label: "Kiribati Line Islands"},
                    {value: "Kiribati_Phoenix_Islands", label: "Kiribati Phoenix Islands"},
                    {value: "Marshall_Islands", label: "Marshall Islands"},
                    {value: "Nauru", label: "Nauru"},
                    {value: "New_Caledonia", label: "New Caledonia"},
                    {value: "Niue", label: "Niue"},
                    {value: "Northern_Marianas", label: "Northern Marianas"},
                    {value: "Palau", label: "Palau"},
                    {value: "Papua_New_Guinea", label: "Papua New Guinea"},
                    {value: "Pitcairn_Islands", label: "Pitcairn Islands"},
                    {value: "Samoa", label: "Samoa"},
                    {value: "Solomon_Islands", label: "Solomon Islands"},
                    {value: "Tokelau", label: "Tokelau"},
                    {value: "Tonga", label: "Tonga"},
                    {value: "Tuvalu", label: "Tuvalu"},
                    {value: "Vanuatu", label: "Vanuatu"},
                    {value: "Wallis_Futuna", label: "Wallis Futuna"}
                ],
                selectedLocation: "Pacific_Region",
                product: [
                    { value: "precip_accumulation", label: "Accumulations", name: "Accumulations" },
                    { value: "precip_anomalies", label: "Anomalies", name: "Anomalies" },
                    { value: "nb_dry_days", label: "Dry Days", name: "Dry_days" },
                    { value: "days_since_rain", label: "Days Since Rain", name: "Days_since_rain" },
                    { value: "EAR", label: "EAR", name: "EAR" },
                    { value: "SPI", label: "SPI", name: "SPI" },
                    { value: "USDM", label: "USDM", name: "USDM" }
                ],
                selectedProduct: "precip_accumulation",
                numDays: [30, 60, 90, 180, 360],
                selectedNumDays: 30,
                period: [
                    { value: "monthly", label: "Monthly" },
                    { value: "seasonal", label: "Seasonal" }
                ],
                selectedPeriod: "monthly",
                distribution: ["decile", "quartile", "tercile"],
                selectedDistribution: "decile",
                leadTime: [1, 2, 3, 4, 5],
                selectedLeadTime: 1,
                numDayObs: [30],
                selectedNumDayObs: 30,
            }
        },
        methods: {
            async parseImageUrl() {
                try {
                    // const response = await axios.get("http://13.236.223.62:60100/icu/image_url");
                    const response = await axios.get("https://api.clidesc.info/icu/image_url");
                    const image_urls = response.data;
                    this.imageUrls = image_urls;
                    this.updateImageUrl();
                } catch (error) {
                    console.error("error ", error);
                }
            },
            async parseUpdateDate(target, targetVar) {
                // const update_date_url = "http://13.236.223.62:60100/icu/" + target + "_update_date";
                const update_date_url = "https://api.clidesc.info/icu/" + target + "_update_date";
                try {
                    const response = await axios.get(update_date_url);
                    const update_date = response.data;
                    // console.log('update_date', update_date);
                    this[targetVar] = update_date;
                } catch (error) {
                    console.error("error ", error);
                }
            },
            switchTab(tabName) {
                this.activeTab = tabName;
                this.updateProduct();
                this.updateDistribution();
                this.updateLeadTime();
                this.updateNumDaysObservation();
                this.updateImageUrl();
            },
            matchLocation() {
                // console.log('location', this.location);
                const location_values = this.locations.map(location => location.value);
                let index = location_values.indexOf(this.location);
                if (index === -1) {
                    this.selectedLocation = "Pacific_Region";
                } else {
                    this.selectedLocation = this.location;
                    let target_location = this.locations[index];
                    this.locations.splice(index, 1);
                    this.locations.unshift(target_location);
                }
                // console.log('selectedLocation', this.selectedLocation);
            },
            updateProduct() {
                if (this.selectedLocation === "Pacific_Region") {
                    this.product = [
                        { value: "precip_accumulation", label: "Accumulations", name: "Accumulations" },
                        { value: "precip_anomalies", label: "Anomalies", name: "Anomalies" },
                        { value: "nb_dry_days", label: "Dry Days", name: "Dry_days" },
                        { value: "days_since_rain", label: "Days Since Rain", name: "Days_since_rain" },
                        { value: "EAR", label: "EAR", name: "EAR" },
                        { value: "SPI", label: "SPI", name: "SPI" },
                        { value: "USDM", label: "USDM", name: "USDM" }
                    ];
                } else {
                    this.product = [
                        { value: "precip_accumulation", label: "Accumulations", name: "Accumulations" },
                        { value: "days_since_rain", label: "Days Since Rain", name: "Days_since_rain" },
                        { value: "EAR", label: "EAR", name: "EAR" },
                        { value: "SPI", label: "SPI", name: "SPI" },
                        { value: "USDM", label: "USDM", name: "USDM" }
                    ];
                }
                this.selectedProduct = this.product[0].value;
                this.updateImageUrl();
                this.updateRoute();
            },
            updateDistribution() {
                if (this.selectedLocation === "Pacific_Region") {
                    this.distribution = ["decile", "quartile", "tercile"];
                } else {
                    this.distribution = ["tercile"];
                }
                this.selectedDistribution = this.distribution[0];
                this.updateImageUrl();
                this.updateRoute();
            },
            updateLeadTime() {
                if (this.selectedPeriod === "monthly") {
                    this.leadTime = [1, 2, 3, 4, 5];
                } else {
                    this.leadTime = [3, 4, 5];
                }
                this.selectedLeadTime = this.leadTime[0];
                this.updateImageUrl();
            },
            updateNumDaysObservation() {
                if (this.selectedPeriod === "monthly") {
                    this.numDayObs = [30];
                } else {
                    this.numDayObs = [90];
                }
                this.selectedNumDayObs = this.numDayObs[0];
                this.updateImageUrl();
            },
            
            updateImageUrl() {
                var filename;
                var image_url;
                if (this.activeTab === "recent_conditions") {
                    // console.log('recent_conditions');
                    // console.log('selectedLocation', this.selectedLocation);
                    // console.log('selectedProduct', this.selectedProduct);
                    // console.log('selectedNumDayObs', this.selectedNumDayObs);
    
                    var base_url = "https://s3.ap-southeast-2.amazonaws.com/icu.niwa/mswep/images/";
                    if (!this.selectedProduct.includes("_") && this.selectedLocation !== "Pacific_Region") {
                        filename = this.selectedProduct + "_" + this.selectedLocation + "_" + this.selectedNumDays + "days_to_latest.png";
                    } else {
                        filename = this.selectedProduct + "_" + this.selectedNumDays + "days_to_latest.png";
                    }
                    // console.log('filename', filename);

                    var selectedLocation;
                    if (this.selectedLocation === "Pacific_Region") {
                        selectedLocation = "Southwest_Pacific";
                    } else {
                        selectedLocation = this.selectedLocation;
                    }

                    for (var i = 0; i < this.product.length; i++) {
                        if (this.selectedProduct === this.product[i].value) {
                            var selectedProductName = this.product[i].name;
                        }
                    }

                    image_url = base_url + selectedLocation + "/" + selectedProductName + "/" + this.selectedNumDays + "/" + filename;
                    // console.log('image_url', image_url);
                    this.imageUrl = image_url;
                    this.updateString = "Latest MSWEP Data Used";
                    this.updateDate = this.MSWEPUpdateDate;

                } else if (this.activeTab === "forecast_and_outlook") {
                    // console.log('forecast_and_outlook');
                    // console.log('selectedLocation', this.selectedLocation);
                    // console.log('selectedPeriod', this.selectedPeriod);
                    // console.log('selectedDistribution', this.selectedDistribution);
                    // console.log('selectedLeadTime', this.selectedLeadTime);

                    var filename_prefix;
                    if (this.selectedLocation === "Pacific_Region") {
                        if (this.selectedDistribution === "quartile") {
                            filename_prefix = "C3S_MME_25prob_" + this.selectedPeriod + "_lead" + this.selectedLeadTime;
                        } else {
                            filename_prefix = "C3S_MME_most_likely_category_" + this.selectedPeriod + "_lead" + this.selectedLeadTime + "_precip_" + this.selectedDistribution;
                        }
                    } else {
                        filename_prefix = this.selectedLocation + "_C3S_MME_most_likely_category_" + this.selectedPeriod + "_lead" + this.selectedLeadTime + "_precip_" + this.selectedDistribution;
                    }

                    // console.log('filename_prefix', filename_prefix);
                    for (let key in this.imageUrls) {
                        if (key.startsWith(filename_prefix)) {
                            image_url = this.imageUrls[key];
                            break;
                        }
                    }
                    // console.log('image_url', image_url);
                    this.imageUrl = image_url;
                    this.updateString = "Update Date";
                    this.updateDate = this.C3SUpdateDate;


                } else if (this.activeTab === "water_stress_outlook") {
                    // console.log('water_stress_outlook');
                    // console.log('selectedPeriod', this.selectedPeriod);
                    // console.log('selectedNumDayObs', this.selectedNumDayObs);
                    var url_prefix = "https://s3.ap-southeast-2.amazonaws.com/icu.niwa/c3s/Water_Stress/images/Southwest_Pacific/";

                    if (this.selectedPeriod === "monthly") {
                        filename = "Water_Stress_Outlook_monthly_latest.png";
                    } else {
                        filename = "Water_Stress_Outlook_seasonal_latest.png";
                    }
                    var url = url_prefix + this.selectedPeriod + "/Water_Stress_Outlook/" + this.selectedNumDayObs + "_days/" + filename;
                    this.imageUrl = url;
                    this.updateString = "Update Date";
                    this.updateDate = this.WaterStressUpdateDate;
                }
            },
            updateRoute() {
                this.$router.push({ name: 'ICU', params: { location: this.selectedLocation } });
            },
        },
        watch: {
            $route(to) {
                console.log('route changed');
                this.location = to.params.location;
                this.matchLocation();
                this.updateImageUrl();
            },
            MSWEPUpdateDate: {
                handler(newValue) {
                    if (newValue) {
                        this.updateDate = newValue;
                    }
                },
                immediate: true
            },
        },
    }
</script>

<style scoped>
    .tab {
        display: inline-block;
        padding: 15px 20px 15px 20px;
        cursor: pointer;
    }
    .tab.active {
        background-color: #ccc;
    }
    img {
        height: 60vh;
        padding: 20px 0 20px 0;
    }
</style>

